import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import styled from "styled-components"
import { color, media } from "../Styles"
import TextSide from "../textSide/TextSide"
import { useTranslation } from "react-i18next"



const Choice = () => {
  const [choice, setChoice] = useState(0)
  const { t } = useTranslation()

  return (
    <StyledChoice>
      <div className="selection">
        <button
          styleless
          className={
            choice === 0 ? "aOR selec active" : "aOR selec"
          }
          onClick={() => setChoice(0)}
        >
          CEO & Founder
        </button>
        <button
          styleless
          className={
            choice === 1 ? "aOR selec active" : "aOR selec"
          }
          onClick={() => setChoice(1)}
        >
          Sobre a Enomac
        </button>
      </div>

      <div className="container">
        <CSSTransition
          in={choice === 0}
          timeout={350}
          classNames={"switch"}
          unmountOnExit
        >
          <div className="container-content">
            <TextSide
              image='about/sergio-queiros.jpg'
              alt='Sérgio Queirós'
              title={t("about", { returnObjects: true }).ceo.title}
              content={t("about", { returnObjects: true }).ceo.text}
              data={t("about", { returnObjects: true })}

              ceo
              small
              reverse
            />
          </div>
        </CSSTransition>
        <CSSTransition
          in={choice === 1}
          timeout={350}
          classNames={"switch"}
          unmountOnExit
        >
          <div className="container-content">
            <TextSide
              image={t("about", { returnObjects: true }).enomac.ceo.img}
              alt='Sérgio Queirós'
              data={t("about", { returnObjects: true })}
            />
          </div>
        </CSSTransition>
      </div>
    </StyledChoice>
  )
}

export default Choice

const StyledChoice = styled.div`
  background-color: ${color.white};

  button {
    background: unset;
    border-radius: unset;
    border: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .selection {
    display: flex;
    justify-content: center;
    padding: 2rem;
    
    .selec {
      font-size: 16px;
      position: relative;
      margin: 0 40px;
      color: #000;
    }
    .active::after {
      content: "";
      background-color: ${color.yellow};
      width: 80px;
      height: 3px;
      position: absolute;
      top: 2em;
      left: 50%;
      transform: translateX(-50%);

      ${media.l`
        top: 3em;
      `}
    }
  }
  .container {
    position: relative;
    .container-content {
      position: relative;
    }
  }

  .switch-enter {
    position: relative;
    opacity: 0 !important;
  }
  .switch-enter-active {
    position: relative;
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switch-exit {
    position: absolute;
    top: 0 !important;
    opacity: 1 !important;
  }
  .switch-exit-active {
    position: absolute;
    top: 0 !important;
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }
`
