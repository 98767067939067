import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from '../components/Hero'
import Marcas from '../components/Marcas'
import { useTranslation } from "react-i18next"

import { graphql } from "gatsby"
import Choice from "../components/doublepage/Choice"

const About = () => {
    const { t } = useTranslation()

    return (
        <Layout data={t("home", { returnObjects: true })}>
            <Seo title="SOBRE NÓS | ENOMAC" />
            <Hero
                image='about/banner-sobrenos.png'
                alt='Enomac'
            />
            
            <Choice />

            <Marcas data={t("home", { returnObjects: true })} />
        </Layout>
    )
}

export default About

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["about", "home", "products"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`