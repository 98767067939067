import * as React from "react"
import styled from 'styled-components'
import { color, media } from './Styles'
import { Image } from './Images'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Logo from '../images/logo-white.svg'

const Hero = ({ image, alt, subtitle, title, slogan, sloganMobile, text, btn, logo }) => {
    const breakpoints = useBreakpoint()

    return (
        <HeroStyled>
            {image && <Image src={image} alt={alt} />}
            <div className='wrapper'>
                {logo &&
                    <Logo />
                }
                {subtitle &&
                    <span className='subtitle'>{subtitle}</span>
                }
                {title &&
                    <h1>{title}</h1>
                }
                {(slogan || sloganMobile) &&
                    <span className='slogan'>
                        {!breakpoints.s ? // IF NOT MOBILE
                            slogan
                            : // IF MOBILE
                            sloganMobile
                        }
                    </span>
                }
                {text &&
                    <p>{text}</p>
                }
                {/*{btn &&
                    <Button/>
                }*/}
            </div>
        </HeroStyled>
    )
}

export default Hero

const HeroStyled = styled.div`
    position:relative;
    overflow:hidden;

    .image{
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
        z-index:-1;   

        :before{
            content:'';
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            opacity: 40%;
            z-index: 1;
        }
    }

    .wrapper{
        max-width:1920px;
        margin:0 auto;
        padding:clamp(30px,15vw,80px) 5%;
        padding-top: 0px;
        padding-bottom: 0px;
        color:${color.white};
        display:flex;
        flex-direction:column;
        gap:2rem;
        height:60vh;
        justify-content: center;

        ${media.m`
        padding-top: 100px;
        padding-bottom: 100px;
        `}

        ${media.m`
            height:initial;
        `}

        svg{
            max-width: 600px;
            margin: 0 auto;

            ${media.l`
                max-width:400px;
            `}

            ${media.m`
                max-width:200px;
            `}
        }

        .subtitle{
            font-weight:500;
            font-size:1.8rem;
            margin-bottom:1rem;

            ${media.m`
                font-size:1.3rem;
            `}
        }

        h1{
            font-weight: 800;
            font-size: 5rem;
            text-transform:uppercase;
            letter-spacing:0.1rem;        
            line-height: 90%;

            ${media.m`
                font-size:3rem;
                line-height:3rem;
            `}
        }

        .slogan{
            font-weight:300;
            font-size: 3.1vw;
            color:${color.yellow};
            white-space:nowrap;
            line-height: 88%;

            ${media.s`
                white-space:break-spaces;
                font-size:1.5rem;
                line-height:2rem;
            `}
        }

        p{
            font-size:1.2rem;
            max-width:800px;
        }

    }
`